import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LunarRover from "../views/submissions/LunarRover.vue";
import LivingSpace from "../views/submissions/LivingSpace.vue";
import LivingSpaceDataSubmission from "../views/submissions/data/LivingSpaceDataSubmission.vue";
import Canvas from "../views/submissions/Canvas.vue";
import Travel4Climate from "../views/submissions/Travel4Climate.vue";
import SubmissionList from "@/views/admin/SubmissionList.vue";
import FormList from "@/views/admin/forms/FormList.vue";
import FormEdit from "@/views/admin/forms/FormEdit.vue";
import Clothing4Climate from "@/views/submissions/Clothing4Climate.vue";
import OutreachRequest from "@/views/submissions/OutreachRequest.vue";
import ZoomForms from "@/views/admin/ZoomForms.vue";
import EnumerationsAdmin from "@/views/admin/EnumerationsAdmin.vue";
import Tomatosphere from "@/views/submissions/Tomatosphere.vue";
import LiveStemTomatosphere from "@/views/submissions/LiveStemTomatosphere.vue";
import Home from "@/views/Home.vue";
import TomatosphereSeedGroups from "@/views/admin/tomatosphere/TomatosphereSeedGroups.vue";
import TomatosphereDataSubmission from "@/views/submissions/data/TomatosphereDataSubmission.vue";
import Unauthorized from "@/views/Unauthorized.vue";
import FormClosed from "@/views/FormClosed.vue";
import StemClub from "@/views/submissions/StemClub.vue";
import StemClubRecordingRequest from "@/views/submissions/StemClubRecordingRequest.vue";
import LTSChallenge from "@/views/submissions/LTSChallenge.vue";
import AdminHome from "@/views/admin/AdminHome.vue";
import LTSChallengeSubmission from "@/views/submissions/view/LTSChallenge/LTSChallengeSubmission.vue";
import LTSChallengeSubmissionList from "@/views/submissions/view/LTSChallenge/LTSChallengeSubmissionList.vue";
import LTSChallengePermission from "@/views/submissions/LTSChallengePermission.vue";
import YVEDelivery from "@/views/submissions/YVEDelivery.vue";
import Innovation from "@/views/submissions/Innovation.vue";
import DailyDiscovery from "@/views/submissions/DailyDiscovery.vue";
import ClimateBook from "@/views/submissions/ClimateBook.vue";
import StemStoryTime from "@/views/submissions/StemStoryTime.vue";
import TSAdventure from "@/views/submissions/TSAdventure.vue";
import CommunityOfPractice from "@/views/submissions/CommunityOfPractice.vue";
import LearningPathways from "@/views/submissions/LearningPathways.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    meta: {
      no_auth: true,
    },
  },
  {
    path: "/FormClosed",
    name: "FormClosed",
    component: FormClosed,
  },
  {
    path: "/LunarRover/:instance?",
    name: "LunarRover",
    component: LunarRover,
  },
  {
    path: "/LivingSpace/:instance?",
    name: "LivingSpace",
    component: LivingSpace,
  },
  {
    path: "/Data/LivingSpace/:instance?",
    name: "LivingSpaceData",
    component: LivingSpaceDataSubmission,
  },
  {
    path: "/Data/Tomatosphere/:instance?",
    name: "TomatosphereData",
    component: TomatosphereDataSubmission,
  },
  {
    path: "/Canvas/:instance?",
    name: "Canvas",
    component: Canvas,
  },
  {
    path: "/Travel4Climate/:instance?",
    name: "Travel4Climate",
    component: Travel4Climate,
  },
  {
    path: "/Clothing4Climate/:instance?",
    name: "Clothing4Climate",
    component: Clothing4Climate,
  },
  {
    path: "/OutreachRequest/:instance?",
    name: "OutreachRequest",
    component: OutreachRequest,
  },
  {
    path: "/Tomatosphere/:instance?",
    name: "Tomatosphere",
    component: Tomatosphere,
  },
  {
    path: "/TSAdventure/:instance?",
    name: "TSAdventure",
    component: TSAdventure,
  },
  {
    path: "/LiveStemTomatosphere/:instance?",
    name: "LiveStemTomatosphere",
    component: LiveStemTomatosphere,
  },
  {
    path: "/CommunityOfPractice/:instance?",
    name: "CommunityOfPractice",
    component: CommunityOfPractice,
  },
  {
    path: "/LearningPathways/:instance?",
    name: "LearningPathways",
    component: LearningPathways,
  },
  {
    path: "/Submissions",
    name: "Submissions",
    component: SubmissionList,
  },
  {
    path: "/Admin",
    name: "AdminHome",
    component: AdminHome,
  },
  {
    path: "/Admin/Forms",
    name: "FormList",
    component: FormList,
  },
  {
    path: "/Admin/Forms/Create",
    name: "FormCreate",
    component: FormEdit,
  },
  {
    path: "/Admin/Forms/Update/:id",
    name: "FormUpdate",
    component: FormEdit,
  },
  {
    path: "/Admin/Enumerations",
    name: "EnumerationsAdmin",
    component: EnumerationsAdmin,
  },
  {
    path: "/Admin/ZoomForms",
    name: "ZoomForms",
    component: ZoomForms,
  },
  {
    path: "/Admin/Tomatosphere/SeedGroups",
    name: "TomatosphereSeedGroups",
    component: TomatosphereSeedGroups,
  },
  {
    path: "/SpringStorytime/:instance?",
    name: "SpringStorytime",
    component: YVEDelivery,
  },
  {
    path: "/Eclipse/:instance?",
    name: "Eclipse",
    component: YVEDelivery,
  },
  {
    path: "/StemClub/:instance?",
    name: "STEMClub",
    component: StemClub,
  },
  {
    path: "/StemClubRecordingRequest/:instance?",
    name: "STEMClubRecordingRequest",
    component: StemClubRecordingRequest,
  },
  {
    path: "/LTSChallenge/:instance?",
    name: "LTSChallenge",
    component: LTSChallenge,
  },
  {
    path: "/View/LTSChallenge",
    name: "LTSChallengeSubmissionList",
    component: LTSChallengeSubmissionList,
  },
  {
    path: "/View/LTSChallenge/:id",
    name: "LTSChallengeSubmission",
    component: LTSChallengeSubmission,
  },
  {
    path: "/LTSChallengePermission/:registration_id?",
    name: "LTSChallengePermission",
    component: LTSChallengePermission,
    meta: {
      no_auth: true,
    },
  },
  {
    path: "/Innovation/:instance?",
    name: "Innovation",
    component: Innovation,
  },
  {
    path: "/DailyDiscovery/:instance?",
    name: "DailyDiscovery",
    component: DailyDiscovery,
  },
  {
    path: "/SummerStemClub/:instance?",
    name: "SummerSTEMClub",
    component: YVEDelivery,
  },
  {
    path: "/ClimateBook/:instance?",
    name: "ClimateBook",
    component: ClimateBook,
  },
  {
    path: "/StemStoryTime/:instance?",
    name: "StemStoryTime",
    component: StemStoryTime,
  },
  //{
  //path: "/about",
  //name: "about",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //component: () =>
  //import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  //},
  /* {
    path: "/LunarRover",
    name: "LunarRover",
    component: LunarRover,
  }, */
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
